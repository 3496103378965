
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Redirect from "./views/pages/Redirect";

function App() {

 

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/:shortlink" element={<Redirect />} />
        <Route path="/" element={<></>}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
