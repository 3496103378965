import { createSlice } from '@reduxjs/toolkit';

export const shortlink = createSlice({
  name: 'shortlink',
  initialState: {
    shortlink:{}
  },
  reducers: {
    setShortlinkReducer: (state, action) => {
      state.shortlink = action?.payload;
    },

  }
});

export const {
    setShortlinkReducer
} = shortlink.actions;

export default shortlink.reducer;
