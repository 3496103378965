import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { getShortLinkAction } from "../../redux/store/shortlink/actions";

export default function Redirect() {
    const { shortlink } = useParams();
    const dispatch = useDispatch();
  
    useEffect(() => {
      if (shortlink) {
        dispatch(getShortLinkAction(shortlink)).then(res=>{
          console.log(res)
            if(res && res?._id){
                window.location.href = res.redirectLink
            }
        })
      }
    }, [shortlink,dispatch]);

    console.log(shortlink);
  return (
    <div>
      <div className="App">
        <h1>Loading...</h1>
      </div>
    </div>
  );
}
