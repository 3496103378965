import * as api from "./api";
import { setShortlinkReducer } from "./reducer";

export const getShortLinkAction = (path) => async (dispatch) => {
  try {
    const { data } = await api.getMainLink(path);
    if (data && data?.data) {
      dispatch(setShortlinkReducer(data.data || {}));
      return data.data
    }
  } catch (err) {}
};
